<template>
  <div class="forgot-password">
    <!-- Step 1 -->
    <div v-if="step === 1" class="forgot-password__step">
      <h2
        class="forgot-password__title"
        :class="step === 1 ? 'step1' : 'step2'"
      >
        Forgot Password?
      </h2>
      <p class="forgot-password__subtitle">
        Enter your email address and we'll send you a verification code.
      </p>
      <form
        @submit.prevent="sendVerificationCode"
        class="forgot-password__form"
      >
        <div class="form-field">
          <label class="forgot-password__label">Email</label>
          <input
            type="email"
            v-model="email"
            required
            class="forgot-password__input"
          />
        </div>
        <button type="submit" class="forgot-password__button">
          Send Verification Code
        </button>
        <p v-if="errorMessage" class="forgot-password__error">
          {{ errorMessage }}
        </p>
      </form>
      <p v-if="step === 1" class="back-button" @click="$emit('close')">Back</p>
    </div>
    <!-- Step 2 -->
    <div v-else-if="step === 2" class="forgot-password__step">
      <h2
        class="forgot-password__title"
        :class="step === 1 ? 'step1' : 'step2'"
      >
        Reset Password
      </h2>
      <p class="forgot-password__subtitle">
        Enter the verification code and your new password.
      </p>
      <form @submit.prevent="resetPassword" class="forgot-password__form">
        <!-- Verification Code -->
        <div class="form-field">
          <label class="forgot-password__label">Verification Code</label>
          <input
            type="text"
            v-model="verificationCode"
            required
            class="forgot-password__input"
          />
        </div>
        <!-- Password -->
        <div class="form-field">
          <label class="forgot-password__label">New Password</label>
          <FieldPassword
            :value="newPassword"
            @passwordChanged="onPasswordChange"
          />
        </div>
        <p class="password-section-cont-helpMessage" style="text-align: center">
          {{ passwordStrings.requirements }}
        </p>
        <button type="submit" class="forgot-password__button">
          Reset Password
        </button>
        <p v-if="errorMessage" class="forgot-password__error">
          {{ errorMessage }}
        </p>
      </form>
    </div>
  </div>
</template>
<script>
import { forgotPassword, confirmForgotPassword } from "../../api/cognito";
import { Passwords } from "../Shared/strings.js";
import FieldPassword from "./FieldPassword";

export default {
  data() {
    return {
      email: "",
      verificationCode: "",
      newPassword: "",
      errorMessage: "",
      step: 1,
    };
  },
  components: { FieldPassword },
  methods: {
    closeModal() {
      this.errorMessage = "";
      this.$emit("close");
    },
    sendVerificationCode() {
      this.errorMessage = "";
      this.$store.dispatch("startLoading", "reset-password");
      forgotPassword(this.email, this.onForgotSuccess, this.onFailure);
    },
    resetPassword() {
      this.errorMessage = "";
      this.$store.dispatch("startLoading", "reset-password");
      confirmForgotPassword(
        this.email,
        this.verificationCode,
        this.newPassword,
        this.onResetSuccess,
        this.onFailure,
      );
    },
    onForgotSuccess() {
      this.$store.dispatch("endLoading", "reset-password");
      this.step = 2;
    },
    onResetSuccess() {
      this.$store.dispatch("endLoading", "reset-password");
      this.closeModal();
    },
    onFailure(error) {
      this.$store.dispatch("endLoading", "reset-password");
      this.errorMessage = error;
    },
    onPasswordChange(password) {
      this.newPassword = password;
    },
  },
  computed: {
    passwordStrings() {
      return Passwords;
    },
  },
};
</script>

<style scoped>
.forgot-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0.5rem;
  max-width: 300px;
}

.forgot-password__step {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  gap: 24px;
}
.forgot-password__title {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
.forgot-password__subtitle {
  margin: 0;
}

.forgot-password__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
}

.back-button {
  display: flex;
  justify-content: center;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  color: var(--clr-primary-700);
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.forgot-password__error {
  font-size: 14px;
  color: var(--clr-danger-700);
  margin-top: 1.85em;
  text-align: center;
}

.password-section-cont-helpMessage {
  margin: 0;
}
</style>
