<template>
  <div class="login-page">
    <div class="login-page-form">
      <LoginForm />
    </div>
    <div class="login-page-help">
      <HelpSign />
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/Forms/LoginForm.vue";
import HelpSign from "../components/NavComponents/HelpSign.vue";

export default {
  name: "default-component",
  props: {},
  model: {
    event: "updateModel",
  },
  components: {
    LoginForm,
    HelpSign,
  },
  created() {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.login-page {
  background-color: #003366;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-help {
    position: fixed;
    bottom: 0.25em;
    left: 97%;
  }
  &-form {
    max-width: 600px;
    width: 100%;
  }
}
</style>
