<template>
  <div class="login-container">
    <img
      class="charity-water-logo"
      alt="Charity:water logo"
      src="../../assets/charity_water_logo_wText.svg"
    />
    <form
      v-if="!showModal"
      class="login-container-form"
      @submit.prevent="onSubmit"
    >
      <!-- Email -->
      <div class="form-field">
        <label class="login-container-form-label">Email</label>
        <input type="email" placeholder="Email" required v-model="email" />
      </div>
      <!-- Password -->
      <div class="form-field">
        <label class="login-container-form-label">Password</label>
        <FieldPassword :value="password" @passwordChanged="onPasswordChange" />
      </div>
      <button class="login-container-form-submitButton" type="submit">
        Sign In
      </button>
    </form>
    <p v-if="this.errorMessage" class="login-container-error">
      {{ this.errorMessage }}
    </p>
    <p
      v-if="!showModal"
      class="login-container-forgotpass"
      @click="showModal = true"
    >
      Forgot Password?
    </p>
    <ForgotPasswordModal v-if="showModal" @close="closeModal" />
  </div>
</template>

<script>
import ForgotPasswordModal from "./ForgotPasswordModal.vue";
import { loginCognito, logoutCognito } from "../../api/cognito";
import store from "../../store/index";
import { generateUserObject } from "../Shared/helpers";
import { Login } from "../Shared/strings";
import { getUserById } from "../../api/api";
import FieldPassword from "../Forms/FieldPassword";

export default {
  components: {
    ForgotPasswordModal,
    FieldPassword,
  },
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      showModal: false,
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.loggedUser;
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    async onSubmit() {
      this.errorMessage = "";
      store.dispatch("startLoading", "authenticate");

      loginCognito(
        this.email,
        this.password,
        this.onLoginSuccess,
        this.onLoginError,
        this.onChangePassword,
      );
    },
    async onLoginSuccess(data) {
      if (data.idToken.payload.roleName === "NoAccess") {
        this.errorMessage = Login.no_access.error;
        logoutCognito();
        store.dispatch("endLoading", "authenticate");
        return;
      }
      try {
        const token = data.idToken.jwtToken;
        const appUser = await getUserById(
          data.idToken.payload["custom:user_id"],
        );
        const user = await generateUserObject(appUser);
        await store.dispatch("setLoggedUser", { user, token });
        store.dispatch("endLoading", "authenticate");
        this.$router.push("/dashboard");
      } catch (err) {
        this.errorMessage = err.response.data.message;
        store.dispatch("endLoading", "authenticate");
      }
    },
    onLoginError(err) {
      this.errorMessage = err.message;
      store.dispatch("endLoading", "authenticate");
    },
    onChangePassword(userAttributes) {
      store.dispatch("endLoading", "authenticate");
      store.dispatch("setTempPassword", this.password);
      sessionStorage.setItem(
        "sessionUserAttributes",
        JSON.stringify(userAttributes),
      );
      this.$router.push("/updatePassword");
    },
    onPasswordChange(password) {
      this.password = password;
    },
  },
};
</script>

<style scoped lang="scss">
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  border-radius: 0.5em;
  gap: 24px;
  padding: 60px 160px;

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    width: 100%;
    gap: 24px;
  }

  &-forgotpass {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--clr-primary-700);
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }

  &-error {
    color: var(--clr-danger-700);
    font-size: 0.8em;
  }
}
</style>
